import * as React from 'react'
import Layout from '../components/Layout'

import styles from './index.module.css'
import { Link } from 'gatsby'

export default class extends React.Component {
    public render() {
        return (
            <Layout>
                <div className={styles.profileWrapper}>
                    <div className={styles.profile} />
                </div>
                <div className={styles.floating}>
                    <h1>Dave Macaulay.</h1>
                    <p>
                        I am an Sr. Engineering Manager based in Austin, TX
                        working at{' '}
                        <a
                            href="https://boldcommerce.com/"
                            rel="noopener"
                            target="_blank"
                        >
                            Bold Commerce
                        </a>
                        .
                    </p>
                    <p>
                        Formally{' '}
                        <a
                            href="https://business.adobe.com/products/magento/magento-commerce.html"
                            rel="noopener"
                            target="_blank"
                        >
                            Adobe Commerce
                        </a>{' '}
                        working on{' '}
                        <a
                            href="https://developer.adobe.com/commerce/pwa-studio/"
                            rel="noopener"
                            target="_blank"
                        >
                            PWA Studio
                        </a>
                        , Magento 2 Core &{' '}
                        <a
                            href="https://magento.com/products/magento-commerce/page-builder"
                            rel="noopener"
                            target="_blank"
                        >
                            Page Builder
                        </a>
                        .
                    </p>
                    <ul className={styles.links}>
                        <li>
                            <Link to="/blog/">
                                <span>Blog</span>.
                            </Link>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/HelloMacaulay"
                                rel="noopener"
                                target="_blank"
                            >
                                <span>Twitter</span>.
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/in/dave-macaulay"
                                rel="noopener"
                                target="_blank"
                            >
                                <span>LinkedIn</span>.
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://github.com/davemacaulay"
                                rel="noopener"
                                target="_blank"
                            >
                                <span>GitHub</span>.
                            </a>
                        </li>
                    </ul>
                </div>
            </Layout>
        )
    }
}
